import axiosInstance from '../../../helpers/api';

export const topPerformancePerGroup = (id) => {
    return axiosInstance.get(`/students/top_performance/per_group`);
};


export const topPerformanceAllGroups = (id) => {
    return axiosInstance.get(`/students/top_performance/all_groups`);
};

export const answersAnalytics = (id) => {
    return axiosInstance.get(`/students/top_performance/exam_analysis`);
};

export const studentPerformanceReport = (id) => {
    return axiosInstance.get(`/students/reports/performance/${id}`);
}