import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* topPerformancePerGroup({ payload }) {
  try {
    yield put(actions.performanceLoading(true));
    const result = yield call(api.topPerformancePerGroup, payload);
    yield put(actions.getTopPerformancePerGroupSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getTopPerformancePerGroupFail());
  } finally {
    yield put(actions.performanceLoading(false));
  }
}

function* topPerformanceAllGroups({ payload }) {
  try {
    yield put(actions.performanceLoading(true));
    const result = yield call(api.topPerformanceAllGroups, payload);
    yield put(actions.getTopPerformanceAllGroupspSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getTopPerformanceAllGroupspFail());
  } finally {
    yield put(actions.performanceLoading(false));
  }
}

function* answersAnalytics({ payload }) {
  try {
    yield put(actions.performanceLoading(true));
    const result = yield call(api.answersAnalytics, payload);
    yield put(actions.getAnswersAnalyticsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getAnswersAnalyticsFail());
  } finally {
    yield put(actions.performanceLoading(false));
  }
}

function* studentPerformance() {
  yield takeLatest(
    Types.GET_TOP_PERFORMANCE_PER_GROUP_REQUEST,
    topPerformancePerGroup
  );
  yield takeLatest(
    Types.GET_TOP_PERFORMANCE_ALL_GROUPS_REQUEST,
    topPerformanceAllGroups
  );
  yield takeLatest(Types.GET_ANSWERS_ANALYTICS_REQUEST, answersAnalytics);
}

function* studentPerformanceReport({ payload }) {
  try {
    yield put(actions.performanceLoading(true));
    const result = yield call(api.studentPerformanceReport, payload.id);
    yield put(actions.getPerformanceAnalyticsSuccess(result));
  }
  catch (error) {
    const { response, message } = error;
    toast.error(message || 'Error fetching performance data');
    yield put(actions.getPerformanceAnalyticsFail(error));
  } finally {
    yield put(actions.performanceLoading(false));
  }
}

export default function* performanceSaga() {
  yield takeLatest(Types.GET_PERFORMANCE_ANALYTICS_REQUEST, studentPerformanceReport);
}
