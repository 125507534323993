import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Heading,
  HStack,
  Icon,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { RiCalendarTodoFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import exams from "../../../assets/icons/exams.svg";
import Layout from "../../../components/student/Layout/layout";
import ExamMainData from "../../../containers/student/exams/exam-main-data";
import SolveHomework from "../../../containers/student/models/solve-homework";
import { openSolveHomeworkModel } from "../../../modules/admin-modules/models/Actions";
import {
  getHomeworkQuizByChapterIdRequest,
  getHomeworksByChapterIdRequest,
} from "../../../modules/students-modules/homework/Actions";

const StudentHomeWork = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [selectedData, setSelectedData] = useState();

  const chapterHomework = useSelector(
    (state) => state.studentHomework.chapterHomeworks
  );

  const chapterHomeworkQuiz = useSelector(
    (state) => state.studentHomework.chapterHomeworkQuiz
  );
  const isLoading = useSelector((state) => state.studentHomework.isLoading);

  useEffect(() => {
    if (id) {
      dispatch(getHomeworksByChapterIdRequest(id));
      dispatch(getHomeworkQuizByChapterIdRequest(id));
    }
  }, [id]);
  const handelSolveExamFun = (data) => {
    window.location.href = `/homework/exam-guide/${data?.id}?name=${data?.name}`;
  };

  const handelCheckExamAnswersFun = (data) => {
    window.location.href = `/exam-result/${data?.id}?name=${data?.name}`;
  };

  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      <Container maxW="container.2xl" h="100%" my="100px" mb="165px" w="100%">
        <VStack spacing={8} h="100%" w="100%" alignItems="flex-start">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/homeworks/sections">
                <Text>Homeworks</Text>
              </Link>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <Text>{searchParams.get("name")}</Text>
            </BreadcrumbItem>
          </Breadcrumb>

          <Heading as="h3" color="main_1" textAlign="center" w="100%">
            {searchParams.get("name")}
          </Heading>
        </VStack>

        <Stack
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          flexWrap="wrap"
          p="5px"
          mt="1.5"
        >
          {chapterHomework?.map((data, idx) => {
            return (
              <Box
                borderRadius="8px"
                w={{ base: "100%", md: "50%", lg: "30%" }}
                cursor="pointer"
                boxShadow={
                  selectedData?.id == data?.id
                    ? "2px 3px 10px #80808045"
                    : "none"
                }
                key={idx}
              >
                <HStack
                  bg="main_1_light"
                  borderRadius="8px"
                  p="10px"
                  color="main_1"
                  alignItems="flex-start"
                  spacing="15px"
                >
                  <Image src={exams} width="45px" background="transparent" />
                  <VStack alignItems="flex-start">
                    <Text
                      fontSize="18px"
                      textTransform="capitalize"
                      fontWeight="500"
                    >
                      {data?.name}{" "}
                      <span style={{ fontSize: "14px", marginInline: "4px" }}>
                        (pdf homework)
                      </span>
                    </Text>

                    <HStack spacing="8px">
                      <Icon as={RiCalendarTodoFill} />
                      <Text>
                        {dayjs(data?.created_at).format("D , MMM ,YYYY")}
                      </Text>
                    </HStack>
                    <Button
                      onClick={() => {
                        if (data?.file) {
                          window.open(
                            `https://docs.google.com/gview?url=${data?.file}`,
                            "_blank"
                          );
                        } else {
                          setSelectedData(data);
                          dispatch(openSolveHomeworkModel());
                        }
                      }}
                      disabled = {data?.active == 0}
                    >
                      {data?.is_solved == 1 ? "Preview" : "Solve"}
                    </Button>
                  </VStack>
                </HStack>
              </Box>
            );
          })}
        </Stack>
        <Stack
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          flexWrap="wrap"
          p="5px"
          mt="1.5"
        >
          {chapterHomeworkQuiz?.map((data, idx) => {
            return (
              <Box
                borderRadius="8px"
                w={{ base: "100%", md: "50%", lg: "30%" }}
              >
                <ExamMainData
                  exam={data}
                  state={data?.right_answers == null ? "Pending" : "Solved"}
                  solveExamFun={handelSolveExamFun}
                  checkExamAnswersFun={handelCheckExamAnswersFun}
                />
              </Box>
            );
          })}
        </Stack>
      </Container>
      <SolveHomework data={selectedData} setSelectedData={setSelectedData} />
    </Layout>
  );
};

export default StudentHomeWork;
