import Types from './Types';

const INITIAL_STATE = {
    perGroup: {},
    allGroups: {},
    answers: {},
    isLoading: false,
    count: '',
    analytics: {},
};

export default function performance(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_TOP_PERFORMANCE_PER_GROUP_SUCCESS: {
            return {
                ...state,
                perGroup: payload.data,
            };
        }
        case Types.GET_TOP_PERFORMANCE_ALL_GROUPS_SUCCESS: {
            return {
                ...state,
                allGroups: payload,
            };
        }

        case Types.GET_ANSWERS_ANALYTICS_SUCCESS: {
            return {
                ...state,
                answers: payload.data,
            };
        }

        case Types.GET_PERFORMANCE_ANALYTICS_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case Types.GET_PERFORMANCE_ANALYTICS_SUCCESS: {
            return {
                ...state,
                analytics: payload.data, // Access data property from payload
                isLoading: false,
            };
        }

        case Types.GET_PERFORMANCE_ANALYTICS_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: payload
            };
        }

        case Types.PERFORMANCE_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.ADD_LESSONS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        default: {
            return state;
        }
    }
}
