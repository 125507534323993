import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* createStudentExam({ payload, action }) {
  try {
    yield put(actions.addStudentExamsLoading(true));
    const result = yield call(api.createStudentExams, payload);
    
    yield put(actions.createStudentExamsSuccess(result.data));
    if (action){
      action();
    }
    else {
      window.location.reload();
    }
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.createStudentExamsFail());
  } finally {
    yield put(actions.addStudentExamsLoading(false));
  }
}

function* getStudentExams() {
  try {
    yield put(actions.addStudentExamsLoading(true));
    const result = yield call(api.getStudentExams);
    yield put(actions.getStudentExamsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getStudentExamsFail());
  } finally {
    yield put(actions.addStudentExamsLoading(false));
  }
}

function* getStudentExamQuestions({ payload }) {
  try {
    yield put(actions.addStudentExamsLoading(true));
    const result = yield call(api.getStudentExamQuestions, payload);
    yield put(actions.getStudentExamQuestionsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getStudentExamQuestionsFail());
  } finally {
    yield put(actions.addStudentExamsLoading(false));
  }
}

export default function* studentExamSaga() {
  yield takeLatest(Types.CREATE_STUDENT_EXAMS_REQUEST, createStudentExam);
  yield takeLatest(Types.GET_STUDENT_EXAMS_REQUEST, getStudentExams);
  yield takeLatest(
    Types.GET_STUDENT_EXAM_QUESTIONS_REQUEST,
    getStudentExamQuestions
  );
}
