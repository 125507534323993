import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  RiGlobalLine,
  RiLogoutBoxRLine,
  RiNotification4Line,
  RiQrScan2Line,
  RiUserLine,
} from "react-icons/ri";
import { logoutUserAction } from "../../../modules/auth/Actions";
import { useDispatch } from "react-redux";
import { openUserDataModel } from "../../../modules/admin-modules/models/Actions";
import student from "../../../assets/icons/student.svg";
import report from "../../../assets/icons/report.svg";
import name from "../../../assets/icons/name.svg";
import mobile from "../../../assets/icons/mobile.svg";
import logout from "../../../assets/icons/logout.svg";
import grade from "../../../assets/icons/grade.svg";
import bookmark from "../../../assets/icons/bookmark.svg";
import MobileNavbar from "./mobileNavbar";
import { useSelector } from "react-redux";

const Navbar = ({ color, bg }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  window.onscroll = function () {
    scrollFn();
  };
  function scrollFn() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById("navbar").style.backgroundColor = "white";
      document.getElementById("navbar").style.color = "#010038";
      document.getElementById(
        "logo"
      ).src = require("../../../assets/colorLogo.webp");
    } else {
      document.getElementById("navbar").style.backgroundColor = "white";
      document.getElementById("navbar").style.color = color;
      document.getElementById("logo").src =
        bg == "white"
          ? require("../../../assets/colorLogo.webp")
          : require("../../../assets/whiteLogo.webp");
    }
  }
  const linksArr = [
    {
      link: "/videos/sections",
      label: "Videos",
      includedName1: "videos",
      includedName2: "video",
    },
    {
      link: "/exams/sections",
      label: "Exams",
      includedName1: "exams",
      includedName2: "exam",
      unincludedName: "student",
    },
    {
      link: "/homeworks/sections",
      label: "Homeworks",
      includedName1: "homeworks",
      includedName2: "homework",
    },
    {
      link: "/student/exams",
      label: "Student Exams",
      includedName1: "student",
      includedName2: "exams",
      unincludedName: "chapters",
    },
    {
      link: "/study-with-enough/chapters",
      label: "Study with enough",
      includedName1: "study-with-enough",
      includedName2: "study",
    },
    {
      link: "/questions",
      label: "Missed questions",
      includedName1: "questions",
      includedName2: "questions",
    },
    // {
    //   link: "/performance-report",
    //   label: "Performance report",
    //   includedName1: "performance-report",
    //   includedName2: "performance-report",
    // },
    
  ];
  return (
    <Flex
      id="navbar"
      position={"fixed"}
      zIndex="100"
      alignItems="center"
      justifyContent="space-between"
      top="0"
      p="5px"
      w="100%"
      color={color}
      bg={bg}
      textTransform={"capitalize"}
    >
      <Box w={"fit-content"}>
        <Image
          src={
            bg == "white"
              ? require("../../../assets/colorLogo.webp")
              : require("../../../assets/whiteLogo.webp")
          }
          w="100px"
          id="logo"
        />
      </Box>
      <HStack
        spacing="20px"
        display={{ base: "none", md: "flex" }}
        fontSize="16px"
        fontWeight="400"
      >
        <Link to={"/home"}>
          <Text
            _hover={{ color: "main_2" }}
            textDecoration={
              window.location.pathname == "/home" ? "underline" : "none"
            }
            fontWeight={window.location.pathname == "/home" ? "600" : "400"}
          >
            home
          </Text>
        </Link>
        {linksArr?.map((data, index) => {
          return (
            <Link to={data?.link} key={index}>
              <Text
                _hover={{ color: "main_2" }}
                textDecoration={
                  !window.location.pathname.includes(data?.unincludedName) &&
                  (window.location.pathname.includes(data?.includedName1) ||
                    window.location.pathname.includes(data?.includedName2))
                    ? "underline"
                    : "none"
                }
                fontWeight={
                  !window.location.pathname.includes(data?.unincludedName) &&
                  (window.location.pathname.includes(data?.includedName1) ||
                    window.location.pathname.includes(data?.includedName2))
                    ? "600"
                    : "400"
                }
              >
                {data?.label}
              </Text>
            </Link>
          );
        })}
      </HStack>

      <HStack display={{ base: "none", md: "flex" }}>
        <IconButton
          variant="outline"
          borderColor="main_1"
          aria-label="Send email"
          icon={<RiGlobalLine color="main_1" fontSize="20px" />}
        />
        <IconButton
          variant="outline"
          borderColor="main_1"
          aria-label="Send email"
          icon={<RiNotification4Line color="main_1" fontSize="20px" />}
        />
        <Menu direction="rtl">
          <MenuButton
            _focus={{ outline: "none" }}
            as={IconButton}
            borderColor="main_1"
            aria-label="Options"
            icon={<RiUserLine color="main_1" fontSize="20px" />}
            variant="outline"
          />
          <MenuList dir="rtl">
            <MenuItem p="7px" mt="10px">
              <HStack spacing="7px">
                <Image src={report} minW="20px" />
                <Text mx="5px">التقارير</Text>
              </HStack>
            </MenuItem>
            <MenuDivider />
            <Link to="/questions">
              <MenuItem p="7px" mt="10px">
                <HStack spacing="7px">
                  <Image src={bookmark} minW="20px" />
                  <Text mx="5px">إشارات مرجعية</Text>
                </HStack>
              </MenuItem>
            </Link>

            <MenuDivider />
            <MenuItem
              p="7px"
              mt="10px"
              onClick={() => dispatch(openUserDataModel())}
            >
              <Flex>
                <Image src={student} minW="20px" />
                <Text mx="7px">بيانات الطالب</Text>
              </Flex>
            </MenuItem>
            <MenuDivider />

            <MenuItem p="7px" mt="10px">
              <HStack spacing="7px">
                <Image src={name} minW="20px" />
                <Text mx="5px">{userData?.name}</Text>
              </HStack>
            </MenuItem>
            <MenuItem p="7px" mt="10px">
              <HStack spacing="7px">
                <Image src={mobile} minW="20px" />
                <Text mx="5px">{userData?.phone} </Text>
              </HStack>
            </MenuItem>
            <MenuItem p="7px" mt="10px">
              <HStack spacing="7px">
                <Image src={grade} minW="20px" />
                <Text mx="5px">{userData?.grade?.name} </Text>
              </HStack>
            </MenuItem>
            <MenuItem
              p="7px"
              mt="10px"
              onClick={() => dispatch(openUserDataModel())}
            >
              <HStack spacing="7px">
                <Icon as={RiQrScan2Line} fontSize="20px" />
                <Text mx="5px">QR Code</Text>
              </HStack>
            </MenuItem>
            <MenuDivider />

            <MenuItem
              p="7px"
              mt="10px"
              onClick={() => dispatch(logoutUserAction())}
            >
              <HStack spacing="7px">
                <Image src={logout} minW="15px" />
                <Text mx="5px">تسجيل الخروج</Text>
              </HStack>
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>

      <Box display={{ base: "block", md: "none" }}>
        <MobileNavbar />
      </Box>
    </Flex>
  );
};

export default Navbar;
