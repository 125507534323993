import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  Legend,
} from "recharts";
import { Brain, Target, Plus } from "lucide-react";
import Layout from "../../../components/student/Layout/layout";
import { getPerformanceAnalyticsRequest } from "../../../modules/students-modules/performance/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { createStudentExamsRequest } from "../../../modules/students-modules/student-exams/Actions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const COLORS = ["#010038", "#f9d822", "#ffffff"];

const StudentPerformanceReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const student = useSelector((state) => state.user.userData);
  const performanceData = useSelector((state) => state.performance.analytics);
  const isLoading = useSelector((state) => state.performance.isLoading);

  const handleCreateMiniQuiz = async (chapterId, chapterName) => {
      dispatch(createStudentExamsRequest({
        sub_type: 3,
        chapters: [
          {
            "chapter_id": chapterId,
            "no_of_questions": 10
          }
        ],
        name: chapterName + " Mini Quiz " + student.name
      }, () => {
        toast.success("Mini Quiz created successfully");
        navigate(`/student/exams`);
      }));
  };

  useEffect(() => {
    if (student?.id) {
      dispatch(getPerformanceAnalyticsRequest(student.id));
    }
  }, [student, dispatch]);

  const wrongChaptersData = performanceData?.most_wrong_chapters || [];

  const studyTips = performanceData?.study_tips || [
    "Review fundamental concepts and formulas",
    "Practice solving example problems",
    "Create visual diagrams to understand different relationships",
  ];

  // Monthly performance data from API
  const monthlyData = (performanceData?.monthly_accuracy || []).map(item => ({
    month: new Date(item.month + "-01").toLocaleDateString('en-US', { month: 'short', year: '2-digit' }),
    accuracy: parseFloat(item.accuracy),
    totalQuestions: item.total_questions,
    totalRight: item.total_right
  }));

  // Chapter performance data from API
  const chapterPerformanceData = (performanceData?.most_wrong_chapters || []).map(chapter => ({
    name: chapter.name,
    correct: chapter.right_count,
    wrong: chapter.wrong_count,
    accuracy: parseFloat(chapter.accuracy)
  }));

  // Target progress data from API
  const targetProgress = (performanceData?.chapter_targets || [])
    .filter(chapter => chapter.total_questions > 0); // Filter out chapters with 0 total questions

  const stats = {
    totalSolved: performanceData?.total_questions_solved || 0,
    totalMissed: performanceData?.total_missed_questions || 0,
    wrongRate: performanceData
      ? ((performanceData.total_missed_questions / performanceData.total_questions_solved) * 100).toFixed(1)
      : "0",
  };

  const statsCards = [
    {
      title: "Total Questions Solved",
      value: stats.totalSolved,
      icon: <Target className="w-6 h-6 text-blue-600" />,
    },
    {
      title: "Wrong Questions Rate",
      value: `${stats.wrongRate}%`,
      icon: <Brain className="w-6 h-6 text-yellow-600" />,
    },
  ];

  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      <div className="min-h-screen bg-white text-gray-800 p-8">
        <div className="max-w-7xl mx-auto">
          <header className="m-8">
            <h1 className="text-3xl font-bold mb-2 text-[#010038]">
              Student Performance Dashboard
            </h1>
            <p className="text-gray-600">Physics - Third Year Secondary</p>
          </header>

          {/* Stats Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            {statsCards.map((card, index) => (
              <div key={index} className="bg-white rounded-lg p-6 shadow-lg border border-gray-100">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-medium text-gray-600">{card.title}</h3>
                  {card.icon}
                </div>
                <p className="text-3xl font-bold text-[#010038]">{card.value}</p>
              </div>
            ))}

            <div className="bg-white rounded-lg p-6 shadow-lg border border-gray-100">
              <h2 className="text-xl font-bold mb-6 text-[#010038]">Questions Distribution</h2>
              <div className="h-32">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={[
                        { name: "Correct", value: stats.totalSolved - stats.totalMissed },
                        { name: "Missed", value: stats.totalMissed }
                      ]}
                      cx="50%"
                      cy="50%"
                      innerRadius={16}
                      outerRadius={32}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {[0, 1].map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>

          </div>

          {/* Chapter Performance and Distribution */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
            <div className="bg-white rounded-lg p-6 shadow-lg border border-gray-100">
              <h2 className="text-xl font-bold mb-6 text-[#010038]">Chapter Performance</h2>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={chapterPerformanceData} layout="vertical">
                    <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                    <XAxis type="number" />
                    <YAxis type="category" dataKey="name" width={200} />
                    <Tooltip
                      formatter={(value, name) => {
                        if (name === "Accuracy") return `${value}%`;
                        return value;
                      }}
                    />
                    <Legend />
                    <Bar dataKey="correct" fill="#010038" name="Correct" stackId="a" />
                    <Bar dataKey="wrong" fill="#f9d822" name="Wrong" stackId="a" />
                    <Line
                      type="monotone"
                      dataKey="accuracy"
                      stroke="#ff0000"
                      name="Accuracy"
                      yAxisId={0}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="bg-white rounded-lg p-6 shadow-lg border border-gray-100">
              <h2 className="text-xl font-bold mb-6 text-[#010038]">Monthly Performance</h2>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={monthlyData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                    <XAxis dataKey="month" />
                    <YAxis yAxisId="left" domain={[0, 100]} />
                    <YAxis yAxisId="right" orientation="right" domain={[0, 'auto']} />
                    <Tooltip />
                    <Legend />
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="accuracy"
                      name="Accuracy (%)"
                      stroke="#010038"
                      strokeWidth={2}
                      dot={{ fill: "#010038" }}
                    />
                    <Line
                      yAxisId="right"
                      type="monotone"
                      dataKey="totalQuestions"
                      name="Total Questions"
                      stroke="#f9d822"
                      strokeWidth={2}
                      dot={{ fill: "#f9d822" }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          {/* Follow Your Target Section */}
          <div className="mb-8">
            <h2 className="text-xl font-bold mb-6 text-[#010038]">Follow Your Target</h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              {/* Target Progress Column */}
              <div className="bg-white rounded-lg p-6 shadow-lg border border-gray-100">
                <div className="overflow-y-auto max-h-[520px] pr-2">
                  {targetProgress.map((chapter, index) => (
                    <div key={chapter.chapter_id} className="border border-gray-100 rounded-lg p-4 mb-4 last:mb-0">
                      <div className="flex items-center justify-between mb-2">
                        <h3 className="font-medium text-[#010038]">{chapter.chapter_name}</h3>
                        <button className="bg-[#010038] text-white px-4 py-2 rounded-lg text-sm flex items-center gap-2 hover:bg-opacity-90 transition-colors" onClick={() => handleCreateMiniQuiz(chapter.chapter_id, chapter.chapter_name)}>
                          <Plus className="w-4 h-4" />
                          Create Mini Quiz
                        </button>
                      </div>
                      <div className="flex items-center gap-4">
                        <div className="flex-1">
                          <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                            <div
                              className="h-full bg-[#f9d822]"
                              style={{ width: `${parseFloat(chapter.completion_percentage)}%` }}
                            ></div>
                          </div>
                        </div>
                        <div className="text-sm text-gray-600 flex flex-col items-end">
                          <span>{chapter.total_solved}/{chapter.total_questions} solved</span>
                          <span className="text-xs text-gray-500">{chapter.remaining_questions} remaining</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Improvement Recommendations Column */}
              <div className="bg-white rounded-lg p-6 shadow-lg border border-gray-100">
                <h2 className="text-xl font-bold mb-6 text-[#010038]">
                  Improvement Recommendations
                </h2>
                <div className="space-y-6">
                  <div className="space-y-4">
                    <h3 className="text-lg font-semibold text-[#010038]">
                      Study Tips
                    </h3>
                    <ul className="list-disc list-inside space-y-2 text-gray-600">
                      {studyTips.map((tip, index) => (
                        <li key={index}>{tip}</li>
                      ))}
                    </ul>
                  </div>

                  <div className="space-y-4">
                    <h3 className="text-lg font-semibold text-[#010038]">
                      Priority Focus Areas
                    </h3>
                    <ul className="list-disc list-inside space-y-2 text-gray-600">
                      {wrongChaptersData.map((chapter, index) => (
                        <li key={index}>
                          Review {chapter.name} concepts ({chapter.wrong_count} missed questions)
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StudentPerformanceReport;