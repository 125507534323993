import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";
import { closeExamAnswersLoading } from "../../admin-modules/models/Actions";
function* getAllExams({ payload }) {
  try {
    yield put(actions.addExamsLoading(true));
    const result = yield call(api.getAllExams, payload);
    yield put(actions.getAllExamsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getAllExamsFail());
  } finally {
    yield put(actions.addExamsLoading(false));
  }
}

function* getExamsByChapterID({ payload }) {
  try {
    yield put(actions.addExamsLoading(true));
    const result = yield call(api.getExamsByChapterID, payload);
    yield put(actions.getExamsByChapterIdSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getExamsByChapterIdFail());
  } finally {
    yield put(actions.addExamsLoading(false));
  }
}
function* getChapterExam({ payload }) {
  try {
    yield put(actions.addExamsLoading(true));
    const result = yield call(api.getChapterExam, payload);
    yield put(actions.getChapterExamSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getChapterExamFail());
  } finally {
    yield put(actions.addExamsLoading(false));
  }
}

function* getChapterExamById({ payload }) {
  try {
    yield put(actions.addExamsLoading(true));
    const result = yield call(api.getChapterExamById, payload);
    yield put(actions.getChapterExamByIdSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getChapterExamByIdFail());
  } finally {
    yield put(actions.addExamsLoading(false));
  }
}
function* getExamsByID({ payload }) {
  try {
    yield put(actions.addExamsLoading(true));
    const result = yield call(api.getExamsByID, payload);
    yield put(actions.getExamsByIdSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getExamsByIdFail());
  } finally {
    yield put(actions.addExamsLoading(false));
  }
}

function* getExamQuestions({ payload }) {
  try {
    yield put(actions.addExamsLoading(true));
    const result = yield call(api.getExamQuestions, payload);
    yield put(actions.getExamQuestionsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getExamQuestionsFail());
  } finally {
    yield put(actions.addExamsLoading(false));
  }
}

function* getExamCorrection({ payload }) {
  try {
    yield put(actions.addExamsLoading(true));
    const result = yield call(api.getExamCorrection, payload);
    yield put(actions.getExamCorrectionSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getExamCorrectionFail());
  } finally {
    yield put(actions.addExamsLoading(false));
  }
}

function* sendAnswers({payload, action}) {
  try {
    yield put(actions.sendAnswersLoading(true));
    const result = yield call(api.sendAnswers, payload);
    yield put(
      actions.sendAnswersSuccess({
        data: result.data,
      })
    );
    if(action){
      action();
    }
  } catch (error) {
    yield put(closeExamAnswersLoading());
    yield put(actions.sendAnswersFail());
    toast.error(error.message || 'Failed to send answers');
  } finally {
    yield put(actions.sendAnswersLoading(false));
  }
}

function* sendChapterExamAnswers(formData) {
  try {
    yield put(actions.sendAnswersLoading(true));
    const result = yield call(api.sendChapterExamAnswers, formData.payload);
    yield put(
      actions.sendChapterExamAnswersSuccess({
        data: result.data,
      })
    );
    formData?.action && formData?.action();
  } catch (error) {
    yield put(closeExamAnswersLoading());
    yield put(actions.sendAnswersLoading(false));
  } finally {
    yield put(actions.sendAnswersLoading(false));
  }
}

function* examRedo(formData) {
  try {
    yield put(actions.sendAnswersLoading(true));
    const result = yield call(api.examRedo, formData.payload);
    yield put(
      actions.examRedoSuccess({
        data: result.data,
      })
    );
    formData?.action && formData?.action();
    toast.success("your request is sent");
  } catch (error) {
    yield put(actions.examRedoFail());
    toast.success(error.data.message.toString());
  } finally {
    yield put(actions.sendAnswersLoading(false));
  }
}

export default function* chapterExamSaga() {
  yield takeLatest(Types.GET_ALL_EXAMS_REQUEST, getAllExams);

  yield takeLatest(Types.GET_EXAMS_BY_CHAPTER_ID_REQUEST, getExamsByChapterID);
  yield takeLatest(Types.GET_EXAMS_BY_ID_REQUEST, getExamsByID);
  yield takeLatest(Types.GET_EXAM_QUESTIONS_REQUEST, getExamQuestions);
  yield takeLatest(Types.GET_EXAM_CORRECTION_REQUEST, getExamCorrection);

  yield takeLatest(Types.SEND_ANSWERS_REQUEST, sendAnswers);
  yield takeLatest(
    Types.SEND_CHAPTER_EXAM_ANSWERS_REQUEST,
    sendChapterExamAnswers
  );

  yield takeLatest(Types.EXAM_REDO_REQUEST, examRedo);

  yield takeLatest(Types.GET_CHAPTER_EXAM_REQUEST, getChapterExam);
  yield takeLatest(Types.GET_CHAPTER_EXAM_DETAILS_REQUEST, getChapterExamById);
}
